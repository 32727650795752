import { api } from "./config/axios";

class _ToolAPI {
    type = "type=home.ToolPage";

    constructor() { }

    get = async (id: string) => {
        const response = await api.request({
            url: `/pages/${id}`,
            method: 'GET'
        })

        return response;
    }

    getBySlug = async (fields?: string[], slug?: string, locale?: string) => {
        const _fields = fields ? '&fields=' + fields.join(',') : '';
        const _slug = slug ? '&slug=' + slug : '';
        const _locale = locale ? '&locale=' + locale : '';
        const params = `${this.type}${_fields}${_slug}${_locale}`
        const response = await api.request({
            url: `/pages/?${encodeURI(params)}`,
            method: 'GET'
        })

        return response;
    }

    getAll = async (fields?: string[], tags?: string[], locale?: string) => {
        const _fields = fields ? '&fields=' + fields.join(',') : '';
        const _tags = tags ? '&tags=' + tags.join(',') : '';
        const _locale = locale ? '&locale=' + locale : '';
        const params = `${this.type}${_fields}${_tags}${_locale}`
        const response = await api.request({
            url: `/pages/?${encodeURI(params)}`,
            method: 'GET'
        })

        return response;
    }
}

export const ToolAPI = new _ToolAPI();