import React from 'react';

export type StepContextType = {
    step: string;
    history: string[];
    path: string[];
    setPath: (path: string[]) => void,
    goToStep: (step: string, resetHistory: boolean, edgeId?: string) => void,
    goBackStep: () => string,
    goBackToStep: (step: string) => void;
}

export const StepContext = React.createContext<StepContextType>({
    step: 'start',
    history: [],
    path: [],
    setPath: (_path: string[]) => undefined,
    goToStep: (_step: string, _resetHistory: boolean = false, _edgeId: string = '') => undefined,
    goBackStep: () => '',
    goBackToStep: (_step: string) => undefined,
} as StepContextType);

export const useSteps = () => {
    return React.useContext(StepContext);
}

type Props = {
    children: any;
}

const StepProvider: React.FC<Props> = ({ children }) => {
    const [history, setHistory] = React.useState<string[]>(['start']);
    const [step, setStep] = React.useState<string>('start');
    const [path, setPath] = React.useState<string[]>([]);
    const sessionStorageKey = 'stepContext';

    React.useEffect(() => {
        const storedStepContext = sessionStorage.getItem(sessionStorageKey);
        if (storedStepContext) {
            const context = JSON.parse(storedStepContext);
            setStep(context.step);
            setHistory(context.history);
            setPath(context.path);
        }
    }, []);

    React.useEffect(() => {
        const currStepContext = {step, history, path};
        sessionStorage.setItem(sessionStorageKey, JSON.stringify(currStepContext));
    }, [step, history, path]);

    const goToStep = (step: string, resetHistory: boolean = false, edgeId?: string) => {
        setStep(step);
        if (resetHistory) {
            setHistory([step]);
        } else {
            setHistory([...history, step]);
        }
        if (edgeId) {
            setPath([...path, edgeId]);
        } else {
            setPath([]);
        }
    }

    const goBackStep = () => {
        if (history.length > 0) {
            const newHistory = [...history];
            const newPath = [...path];
            newHistory.pop();
            newPath.pop();
            setHistory(newHistory.filter((v, i, self) => self.indexOf(v) === i));
            setPath(newPath);
            setStep(newHistory[newHistory.length - 1]);
            return newHistory[newHistory.length - 1]
        }
        return ''
    }

    const goBackToStep = (step: string) => {
        const sliceIndex = history.findIndex(h => h === step);
        setHistory([...history.slice(0, sliceIndex + 1)])
        setPath([...path.slice(0, sliceIndex)]);
        setStep(step);
    }

    return (
        <StepContext.Provider value={{ step, history, path, setPath, goToStep, goBackStep, goBackToStep }}>
            {children}
        </StepContext.Provider>
    );
}
export default StepProvider;
