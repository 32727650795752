import React from 'react';
import { ResourceAPI } from '../../services/api/resource';
import { ResourcePage } from '../../types/resource';

import { useQuery } from 'react-query';
import { ToolAPI } from '../../services/api/tool';
import { Tag, TagType, ToolPage } from '../../types/resource';
import { useTools } from '../../context/ToolContext';


const fetchResources = async (tags: Tag[], tagType: TagType) => {
    const fields = ['*'];
    const _tags = tags.filter(t => t.type === tagType).map(t => t.name);
    const resources = await Promise.all(
        _tags.map(async (t) => {
            const params = {
                fields: fields.join(','),
                tags: t
            }
            const response = await ResourceAPI.getAll(params);
            return response.data.items;
        })
    )
    return resources?.flat(1).filter((v, i, arr) => arr.findIndex(r => r.id === v.id) === i) ?? [];
}

const fetchAllResources = async () => {
    const params = {
        fields: '*',
        limit: 300,
        order: 'title'
    }
    const response = await ResourceAPI.getAll(params);
    return response.data.items ?? [];
}


const useResourcesHook = () => {
    const [tags, setTags] = React.useState<Tag[]>([]);
    const [currentId, setCurrentId] = React.useState<string>('');
    const [currentSlug, setCurrentSlug] = React.useState<string>('');

    const { toolSlugs } = useTools();

    const fetchCurrent = async () => {
        // can be based on either id (internal link) or slug (query param)
        if (currentId) {
            const response = await ResourceAPI.get(currentId);
            return response?.data;
        } else if (currentSlug) {
            const params = { fields: '*' }
            const response = await ResourceAPI.getBySlug(currentSlug, params);
            return response?.data?.items[0];
        } else {
            return undefined;
        }
    }

    const { data: resources, isFetching: loadingResources } = useQuery<ResourcePage[], boolean>(
        ['resources'],
        fetchAllResources,
        { initialData: [], refetchOnWindowFocus: false }
    )

    const { data: current, isFetching: loadingCurrent } = useQuery<ResourcePage | undefined, boolean>(
        ['current', currentId],
        fetchCurrent,
        { initialData: undefined, refetchOnWindowFocus: false }
    )

    const { data: highlightResources, isFetching: loadingHighlightResources } = useQuery<ResourcePage[], boolean>(
        ['highlight', tags],
        () => fetchResources(tags, 'highlight'),
        { initialData: [], refetchOnWindowFocus: false }
    )

    const { data: l1Resources, isFetching: loadingL1Resources } = useQuery<ResourcePage[], boolean>(
        ['l1', tags],
        () => fetchResources(tags, 'l1'),
        { initialData: [], refetchOnWindowFocus: false }
    )

    const { data: l2Resources, isFetching: loadingL2Resources } = useQuery<ResourcePage[], boolean>(
        ['l2', 'resources', current],
        () => {
            const _tags = current?.tags.filter(t => t.type === 'l2' && t.name.includes(current.title)) ?? []; // only l2 tags that match the current title are included, otherwise all the pages the current resource is included on would also be fetched
            return fetchResources(_tags, 'l2')
        },
        { initialData: [], refetchOnWindowFocus: false }
    )

    const fetchL2Tools = async () => {
        const l2ToolTags = current?.tags?.filter(tag => toolSlugs?.includes(tag.slug)) ?? [];
        const l2s = await Promise.all(
            l2ToolTags.map(async (t) => {
                const response = await ToolAPI.getAll(['*'], [t.name]);
                return response.data.items;
            }) ?? []
        )
        return l2s?.flat(1)?.filter((t: ToolPage) => t.id !== current?.id) ?? [];
    }

    const { data: l2Tools, isFetching: loadingL2Tools } = useQuery(
        ['l2', 'tools', tags, current?.id],
        () => fetchL2Tools(),
    )

    return { 
        setTags, setCurrentId, setCurrentSlug,
        current, loadingCurrent,
        resources, loadingResources,
        highlightResources, loadingHighlightResources,
        l1Resources, loadingL1Resources,
        l2Resources, loadingL2Resources,
        l2Tools, loadingL2Tools
    };
};

export default useResourcesHook;