import React from 'react';
import { ToolAPI } from '../../services/api/tool';
import { ToolPageSlug } from '../../types/page';
import { ToolPage } from '../../types/resource';


export type ToolContextType = {
    currentSlug: ToolPageSlug | undefined;
    tools: ToolPage[];
    toolSlugs: string[],
    setCurrentSlug: (slug: ToolPageSlug | undefined) => void;
    setTools: (tools: ToolPage[]) => void,
}

export const ToolContext = React.createContext<ToolContextType>({
    currentSlug: undefined,
    tools: [],
    toolSlugs: [],
    setCurrentSlug: (_slug: ToolPageSlug) => undefined,
    setTools: (_tools: ToolPage[]) => undefined,
} as ToolContextType);

export const useTools = () => {
    return React.useContext(ToolContext);
}

type Props = {
    children: any;
}

const ToolProvider: React.FC<Props> = ({ children }) => {
    const [currentSlug, setCurrentSlug] = React.useState<ToolPageSlug | undefined>();
    const [tools, setTools] = React.useState<ToolPage[]>([]);
    const [toolSlugs, setToolSlugs] = React.useState<string[]>([]);

    React.useEffect(() => {
        const getToolSlugs = async () => {
            const response = await ToolAPI.getAll();
            setToolSlugs(response?.data?.items?.map((toolPage: ToolPage) => toolPage.meta.slug))
        }

        getToolSlugs();
    }, [])

    return (
        <ToolContext.Provider value={{
            currentSlug,
            tools,
            toolSlugs,
            setCurrentSlug,
            setTools,
        }}>
            {children}
        </ToolContext.Provider>
    );
}
export default ToolProvider;
