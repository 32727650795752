exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-resources-index-tsx": () => import("./../../../src/pages/resources/index.tsx" /* webpackChunkName: "component---src-pages-resources-index-tsx" */),
  "component---src-pages-resources-template-tsx": () => import("./../../../src/pages/resources/template.tsx" /* webpackChunkName: "component---src-pages-resources-template-tsx" */),
  "component---src-pages-self-directed-index-tsx": () => import("./../../../src/pages/self-directed/index.tsx" /* webpackChunkName: "component---src-pages-self-directed-index-tsx" */),
  "component---src-pages-step-index-tsx": () => import("./../../../src/pages/step/index.tsx" /* webpackChunkName: "component---src-pages-step-index-tsx" */),
  "component---src-pages-step-initial-work-up-index-tsx": () => import("./../../../src/pages/step/initial-work-up/index.tsx" /* webpackChunkName: "component---src-pages-step-initial-work-up-index-tsx" */),
  "component---src-pages-step-start-index-tsx": () => import("./../../../src/pages/step/start/index.tsx" /* webpackChunkName: "component---src-pages-step-start-index-tsx" */)
}

