import React from 'react';
import { AlgorithmAPI } from '../../services/api/algorithm';
import { CustomNode } from '../../types/nodes';
import { CustomEdge } from '../../types/edges';

export type AlgorithmContextType = {
    nodes: CustomNode[];
    edges: CustomEdge[];
}

export const AlgorithmContext = React.createContext<AlgorithmContextType>({
    nodes: [],
    edges: [],
} as AlgorithmContextType);

export const useAlgorithm = () => {
    return React.useContext(AlgorithmContext);
}

type Props = {
    children: any;
}

const AlgorithmProvider: React.FC<Props> = ({ children }) => {
    const [nodes, setNodes] = React.useState<CustomNode[]>([]);
    const [edges, setEdges] = React.useState<CustomEdge[]>([]);

    React.useEffect(() => {
        const fetchNodes = async () => {
            const n = await AlgorithmAPI.getAllNodes();
            setNodes(n.data ?? []);
        }
        const fetchEdges = async () => {
            const e = await AlgorithmAPI.getAllEdges();
            setEdges(e.data ?? []);
        }

        fetchNodes();
        fetchEdges();
    }, [])

    return (
        <AlgorithmContext.Provider value={{ nodes, edges }}>
            {children}
        </AlgorithmContext.Provider>
    );
}
export default AlgorithmProvider;
