import { api } from "./config/axios";

class _ResourceAPI {
    type = "home.ResourcePage";

    constructor() { }

    get = async (id: string) => {
        const response = await api.request({
            url: `/pages/${id}`,
            method: 'GET'
        })

        return response;
    }

    getBySlug = async (slug: string, params?: any) => {
        const response = await api.request({
            url: `/pages/`,
            method: 'GET',
            params: {
                type: this.type,
                slug: slug,
                ...params
            }
        })

        return response;
    }

    getAll = async (params?: any) => {
        const response = await api.request({
            url: `/pages/`,
            method: 'GET',
            params: {
                type: this.type,
                ...params
            }
        })

        return response;
    }

    getAllParams = async (params?: any) => {
        const response = await api.request({
            url: `/pages/`,
            method: 'GET',
            params: params
        })

        return response;
    } 
}

export const ResourceAPI = new _ResourceAPI();