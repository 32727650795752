import { navigate } from 'gatsby';
import React from 'react';
import useResourcesHook from '../../hooks/useResources';
import { ResourcePage, Tag, TagType } from '../../types/resource';
import { isBrowser } from '../../utils/isBrowser';
import { useTools } from '../ToolContext';


export type ResourceContextType = {
    current: ResourcePage | undefined;
    currentType: TagType | undefined;
    history: ResourcePage[];
    loadingCurrent: boolean;
    loadingResources: boolean;
    loadingHighlights: boolean;
    loadingL1s: boolean;
    loadingL2s: boolean;
    resources: ResourcePage[] |undefined;
    highlightResources: ResourcePage[] | undefined;
    l1Resources: ResourcePage[] | undefined;
    l2Resources: ResourcePage[] | undefined;
    drawerOpenable: boolean;
    setCurrentId: (id: string) => void;
    setCurrentSlug: (slug: string) => void;
    setCurrentType: (type: TagType | undefined) => void;
    setTags: (tags: Tag[]) => void;
    setHistory: (history: ResourcePage[]) => void;
    stepBack: () => void;
    setLanguage: (language: string) => void;
}

export const ResourceContext = React.createContext<ResourceContextType>({
    current: undefined,
    currentType: undefined,
    history: [],
    loadingCurrent: false,
    loadingResources: false,
    loadingHighlights: false,
    loadingL1s: false,
    loadingL2s: false,
    resources: [],
    highlightResources: [],
    l1Resources: [],
    l2Resources: [],
    drawerOpenable: false,
    setCurrentId: (_id: string) => undefined,
    setCurrentSlug: (_slug: string) => undefined,
    setCurrentType: (_type: TagType | undefined) => undefined,
    setTags: (_tags: Tag[]) => undefined,
    setHistory: (_history: ResourcePage[]) => undefined,
    stepBack: () => undefined,
    setLanguage: (_language: string) => undefined
} as ResourceContextType);

export const useResources = () => {
    return React.useContext(ResourceContext);
}

type Props = {
    children: any;
}

const ResourceProvider: React.FC<Props> = ({ children }) => {
    if (!isBrowser()) return;
    const [language, setLanguage] = React.useState<string>('en'); // i18next provider plugin is nested below this provider, so we use state and expose the setter
    const {
        setCurrentId, setCurrentSlug, setTags,
        current, loadingCurrent, 
        resources, loadingResources,
        highlightResources, loadingHighlightResources,
        l1Resources, loadingL1Resources,
        l2Resources, loadingL2Resources,
        l2Tools
    } = useResourcesHook();
    const [history, setHistory] = React.useState<ResourcePage[]>([]); // history of resources viewed
    const [currentIndex, setCurrentIndex] = React.useState<number>(-1); // index of the current resource within history
    const [currentType, setCurrentType] = React.useState<TagType | undefined>();
    const [drawerOpenable, setDrawerOpenable] = React.useState<boolean>(false);

    React.useEffect(() => {
        setDrawerOpenable(!location.pathname.includes('resources'));
    }, [location.pathname])

    const { setTools } = useTools();

    React.useEffect(() => {
        setTools(l2Tools ?? []);
    }, [l2Tools])

    React.useEffect(() => {
        if (!loadingCurrent) {
            // not in resource center, pop out into drawer
            if (current && !location.pathname.includes('resources')) {
                setHistory(currentIndex > -1 ? [...history.slice(0, currentIndex), current] : [...history, current]);
                navigate(`?resource=${current.meta.slug}`);
            // in resource center, navigate to resource page
            } else if (current && location.pathname.includes('resources')) {
                setHistory(currentIndex > -1 ? [...history.slice(0, currentIndex), current] : [...history, current]);
                navigate(`/resources/${current.meta.slug}/`);
            // no current resource
            } else {
                setHistory([]);
                navigate('');
            }
        }
    }, [current, currentIndex, loadingCurrent])
    

    React.useEffect(() => {
        setCurrentIndex(history.findIndex(s => s.id === current?.id));
    }, [history, loadingCurrent, current?.id])

    React.useEffect(() => {
        const params = new URLSearchParams(location?.search);
        const search = params.get('resource');
        setCurrentSlug(search ?? '');
    }, [location.search])

    const stepBack = () => {
        setCurrentId(`${currentIndex ? history[currentIndex - 1].id : ''}`);
        setHistory([...history.slice(0, currentIndex)]);
    }

    // used for internal links that are generated by wagtail
    React.useEffect(() => {
        const internalLinks = document.querySelectorAll('.internal-link');
        Array.from(internalLinks).forEach((e) => {
            const id = e.getAttribute('id');
            if (id) {
                e.addEventListener('click', () => setCurrentId(id));
            }
        })

        // cleanup
        return () => {
            Array.from(internalLinks).forEach((e) => {
                const id = e.getAttribute('id');
                if (id) {
                    e.removeEventListener('click', () => setCurrentId(id));
                }
            })
        }
    })

    return (
        <ResourceContext.Provider value={{
            current,
            currentType,
            loadingCurrent,
            history,
            loadingResources,
            loadingHighlights: loadingHighlightResources,
            loadingL1s: loadingL1Resources,
            loadingL2s: loadingL2Resources,
            resources,
            highlightResources,
            l1Resources,
            l2Resources,
            drawerOpenable,
            setCurrentId,
            setCurrentSlug,
            setCurrentType,
            setTags,
            setHistory,
            stepBack,
            setLanguage
        }}>
            {children}
        </ResourceContext.Provider>
    );
}
export default ResourceProvider;
