import { ConfigProvider } from 'antd';
import "@fontsource-variable/manrope";
import "bootstrap/dist/js/bootstrap.min.js";
import type { GatsbyBrowser } from "gatsby";
import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactFlowProvider } from 'reactflow';
import AlgorithmProvider from "./src/context/AlgorithmContext";
import ResourceProvider from "./src/context/ResourceContext";
import StepProvider from "./src/context/StepContext";
import ToolProvider from "./src/context/ToolContext";
import "./src/styles/index.scss";


const queryClient = new QueryClient();

export const wrapRootElement: GatsbyBrowser["wrapPageElement"] = ({ element }) => {
  return (
    <ConfigProvider theme={{
      token: {
        colorPrimary: "#D71426",
      },
      components: {
        Message: {
          colorBgElevated: "rgba(0, 0, 0, 0.85)",
          colorText: "rgba(255, 255, 255, 1)",
        }
      }
    }}>
      <QueryClientProvider client={queryClient}>
        <AlgorithmProvider>
          <ReactFlowProvider>
            <StepProvider>
              <ToolProvider>
                <ResourceProvider>
                  {element}
                </ResourceProvider>
              </ToolProvider>
            </StepProvider>
          </ReactFlowProvider>
        </AlgorithmProvider>
      </QueryClientProvider>
    </ConfigProvider >
  );
};